var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Destination & Fare "),_c('v-spacer'),_c('add-button',{attrs:{"permission":"fare-create"},on:{"action":function($event){(_vm.form.dialog = true),
                _vm.getRoutes(),
                _vm.$refs.form ? _vm.$refs.form.reset() : ''}}},[_vm._v(" Assign Fare ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.items.data,"options":_vm.pagination,"footer-props":_vm.footerProps,"server-items-length":_vm.form.items.meta.total,"loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var index = ref.index;
                var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + _vm.form.items.meta.from))]),_c('td',[_vm._v(_vm._s(item.destination))]),_c('td',[_vm._v(_vm._s(item.rate))]),_c('td',[_vm._v(" "+_vm._s(item.route)+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"padding":"0 9px !important","min-width":"100px"}},[_c('div',{staticClass:"btn_group"},[_c('edit-button',{attrs:{"permission":"fare-update"},on:{"agree":function($event){_vm.form.edit(item), _vm.getRoutes()}}}),_c('delete-button',{attrs:{"permission":"fare-delete"},on:{"agree":function($event){return _vm.form.delete(item.id)}}})],1)])])]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.form.dialog),callback:function ($$v) {_vm.$set(_vm.form, "dialog", $$v)},expression:"form.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"title"},[_vm._v("Add/Update")])]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},nativeOn:{"keydown":function($event){return _vm.form.errors.clear($event.target.name)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs8":""}},[_c('v-text-field',{staticClass:"pa-0 pl-2",attrs:{"autocomplete":"off","label":"Destination name*","required":"","name":"destination_name","error-messages":_vm.form.errors.get('destination'),"outlined":"","dense":"","rules":[
                    function (v) { return !!v || 'Destination Name Field is required'; } ]},model:{value:(_vm.form.destination),callback:function ($$v) {_vm.$set(_vm.form, "destination", $$v)},expression:"form.destination"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{staticClass:"pa-0 pl-2",attrs:{"autocomplete":"off","type":"number","label":"Fare amount*","required":"","name":"fare_amount","error-messages":_vm.form.errors.get('rate'),"outlined":"","dense":"","rules":[
                    function (v) { return !!v || 'Fare Amount Name Field is required'; } ]},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}})],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-select',{staticClass:"pa-0 pl-2",attrs:{"items":_vm.routes,"label":"Route*","required":"","name":"route_name","error-messages":_vm.form.errors.get('route_id'),"outlined":"","dense":""},model:{value:(_vm.form.route_id),callback:function ($$v) {_vm.$set(_vm.form, "route_id", $$v)},expression:"form.route_id"}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('v-select',{staticClass:"pa-0 pl-2",attrs:{"disabled":_vm.vehicles.length < 1,"items":_vm.vehicles,"label":"Vehicle*","required":"","name":"vehicle_id","error-messages":_vm.form.errors.get('vehicle_id'),"outlined":"","dense":"","rules":[function (v) { return !!v || 'Route Name Field is required'; }]},model:{value:(_vm.form.vehicle_id),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_id", $$v)},expression:"form.vehicle_id"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){(_vm.form.dialog = false), _vm.form.reset()}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.validate}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }