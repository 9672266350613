<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Destination & Fare
            <v-spacer></v-spacer>
            <add-button
              @action="
                (form.dialog = true),
                  getRoutes(),
                  $refs.form ? $refs.form.reset() : ''
              "
              permission="fare-create"
            >
              Assign Fare
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td>{{ item.destination }}</td>
                <td>{{ item.rate }}</td>
                <td>
                  {{ item.route }}
                </td>
                <td
                  class="text-right"
                  style="padding: 0 9px !important; min-width: 100px"
                >
                  <div class="btn_group">
                    <edit-button
                      permission="fare-update"
                      @agree="form.edit(item), getRoutes()"
                    />
                    <delete-button
                      permission="fare-delete"
                      @agree="form.delete(item.id)"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog v-model="form.dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <br />

              <v-layout row wrap>
                <!--<v-flex xs12 sm12>-->
                <!--</v-flex>-->
                <v-flex xs8>
                  <v-text-field
                    autocomplete="off"
                    label="Destination name*"
                    required
                    class="pa-0 pl-2"
                    v-model="form.destination"
                    name="destination_name"
                    :error-messages="form.errors.get('destination')"
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'Destination Name Field is required',
                    ]"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    label="Fare amount*"
                    required
                    class="pa-0 pl-2"
                    v-model="form.rate"
                    name="fare_amount"
                    :error-messages="form.errors.get('rate')"
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'Fare Amount Name Field is required',
                    ]"
                  />
                </v-flex>
                <v-flex xs7>
                  <v-select
                    :items="routes"
                    label="Route*"
                    required
                    class="pa-0 pl-2"
                    v-model="form.route_id"
                    name="route_name"
                    :error-messages="form.errors.get('route_id')"
                    outlined
                    dense
                  />
                </v-flex>

                <v-flex xs5>
                  <v-select
                    :disabled="vehicles.length < 1"
                    :items="vehicles"
                    label="Vehicle*"
                    required
                    class="pa-0 pl-2"
                    v-model="form.vehicle_id"
                    name="vehicle_id"
                    :error-messages="form.errors.get('vehicle_id')"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Route Name Field is required']"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<style scoped></style>
<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],
  name: "VehicleComponent",
  components: {
    // RemoveDialog
  },
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        route_id: "",
        vehicle_id: "",
        destination: "",
        rate: "",
      },
      "api/fare"
    ),
    headers: [
      { text: "#", align: "left", value: "sno", sortable: false, width: 10 },
      { text: "Destination", align: "left", value: "name", sortable: false },
      { text: "Fare", align: "left", value: "serial", sortable: false },
      { text: "Route", align: "left", value: "type", sortable: false },
      { text: "Action", align: "right", sortable: false },
    ],
    vehicles: [],
    pagination: {
      itemsPerPage: 25,
    },
    types: [
      { text: "Bus", value: "bus" },
      { text: "Van", value: "van" },
      { text: "Micro", value: "micro" },
    ],
    routes: [],
    routeColl: [],
    search: "",
  }),
  watch: {
    "form.route_id": function() {
      let $this = this;
      this.routeColl.forEach(function(route) {
        if ($this.form.route_id === route.id) {
          $this.vehicles = route.vehicles.map(function(v) {
            return { text: v.name, value: v.id };
          });
        }
      });
    },
    pagination: function() {
      this.get();
    },
  },
  computed: {},
  mounted() {
    // this.get();
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    // queryString() {
    //     let json = this.pagination;
    //     return '?' +
    //         Object.keys(json).map(function (key) {
    //             if (![null, undefined].includes(json[key]))
    //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    //         }).join('&');
    // },
    get(params) {
      let extraParams = "";
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    store() {
      this.form.store();
    },
    getRoutes() {
      this.$rest.get("/api/route?rowsPerPage=50").then(({ data }) => {
        this.routeColl = data.data;
        let $this = this;
        this.routes = data.data.map((route) => {
          if ($this.form.route_id === route.id) {
            $this.vehicles = route.vehicles.map(function(v) {
              return { text: v.name, value: v.id };
            });
          }
          return { text: route.name, value: route.id };
        });
      });
    },
  },
};
</script>
